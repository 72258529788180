@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar{
    background-color: #e0e0e0;
    width: 12px;
}
::-webkit-scrollbar-thumb{
    background-color: #14B8A6;
    border-radius: 10px;
}
::-webkit-scrollbar-track{
    background-color: #e0e0e0;
}