.login-container {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center ;
  align-items: center;
  box-shadow:inset 0px 0px 100vh  #0E2636;
  }

.logincard{
  display: flex;
  backdrop-filter: blur(.5vh) brightness(.8);
  height: auto;
  border-radius: 2vh;
  flex-direction: column;
  border: rgba(255, 255, 255, 0.384) 1px solid;
  justify-content: space-around;
  align-items:center;
  width: 60vh;
}
.loginh1{
  margin-top: 5vh;
  color: #fff;
  font-weight: bold;
  font-size: 6vh;
  text-align: center;
}

.loginlabel{
  color: white;
  letter-spacing: .2vh;
  padding-top: 3vh;
  padding-bottom: .4vh;
  font-weight: bold;
  font-size: 1.5vh;
}
input{
  background-color: white;
  padding: .2vh 1vh;
  width: 40vh;
  height: 4vh;
  padding-left: 2vh;
  border-radius: .5vh;
  border: none;
  font-size: 1.7vh;
  letter-spacing: .1vh;
  transition: all ease .4s;
}

.logitems{
  display: flex;
  flex-direction: column;
}
.logbtn{
  background-color: #1DC3B3;
  height: 7vh;
  border: none;
  border-radius: 1vh;
  color: white;
  margin-top: 3vh;
  font-size: 2vh;
  letter-spacing: .3vh;
  transition: all ease .4s;
}
.logbtn:hover{
  background-color: #0E2636;
  transition: all ease .4s;
  font-size: 2.5vh;
  box-shadow: 0px 0px 50px #1DC3B3 ;
}
input:focus{
  transition: all ease .4s;
  box-shadow: 0px 0px 50px #1DC3B3 ;
}